// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import bootstrap from "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/css/bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@oddcamp/cocoon-vanilla-js";
import "lightgallery/dist/css/lightgallery.css"
import postscribe from 'postscribe';
import "chartkick/chart.js"
import 'bs5-lightbox'
import CircleProgress from "packs/circle-progress";
import Trix from "trix"
global.Trix = Trix;
import "@rails/actiontext"
require("trix_customization")
import "controllers"

require("@rails/actiontext")

global.bootstrap = bootstrap;
global.postscribe = postscribe;
global.CircleProgress = CircleProgress

global.Rails = Rails;

Rails.start();
// Turbolinks.start();
ActiveStorage.start();

window.autoGrow = function (oField) {
    if (oField.scrollHeight > oField.clientHeight) {
        oField.style.height = oField.scrollHeight + "px";
    }
}

document.addEventListener("DOMContentLoaded", function(event) {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
})
