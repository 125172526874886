/**
 * File generated by js-routes 2.1.0
 * Based on Rails 6.1.3.1 routes of App::Application
 */
const __jsr = ((that) => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const Root = that;
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define(routes) {
                Utils.namespace(Root, null, routes);
            },
            isSupported() {
                return !!Root;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {};
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, "")) || "http";
        }
        current_port() {
            var _a;
            return ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        namespace(object, namespace, routes) {
            const parts = (namespace === null || namespace === void 0 ? void 0 : namespace.split(".")) || [];
            if (parts.length === 0) {
                return routes;
            }
            for (let index = 0; index < parts.length; index++) {
                const part = parts[index];
                if (index < parts.length - 1) {
                    object = object[part] || (object[part] = {});
                }
                else {
                    return (object[part] = routes);
                }
            }
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})(this);
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /backoffice/users/:id/autocomplete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_backoffice_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"autocomplete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/detailed_sets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_detailed_set_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"detailed_sets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/detailed_sets/:detailed_set_id/detailed_set_configs/:id(.:format)
 * @param {any} detailed_set_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_detailed_set_detailed_set_config_path = __jsr.r({"detailed_set_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"detailed_sets"],[2,[7,"/"],[2,[3,"detailed_set_id"],[2,[7,"/"],[2,[6,"detailed_set_configs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/detailed_sets/:detailed_set_id/detailed_set_configs(.:format)
 * @param {any} detailed_set_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_detailed_set_detailed_set_configs_path = __jsr.r({"detailed_set_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"detailed_sets"],[2,[7,"/"],[2,[3,"detailed_set_id"],[2,[7,"/"],[2,[6,"detailed_set_configs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/detailed_sets/:detailed_set_id/detailed_set_users(.:format)
 * @param {any} detailed_set_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_detailed_set_detailed_set_users_path = __jsr.r({"detailed_set_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"detailed_sets"],[2,[7,"/"],[2,[3,"detailed_set_id"],[2,[7,"/"],[2,[6,"detailed_set_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/detailed_set_users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_detailed_set_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"detailed_set_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/detailed_sets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_detailed_sets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"detailed_sets"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/exam_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_exam_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/exam_types/:exam_type_id/exams/:id(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_exam_type_exam_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"exams"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/exam_types/:exam_type_id/exams(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_exam_type_exams_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"exams"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/exam_types/:exam_type_id/question_sources/:id(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_exam_type_question_source_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"question_sources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/exam_types/:exam_type_id/question_sources(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_exam_type_question_sources_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"question_sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/exam_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_exam_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"exam_types"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/legacy_fetchers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_legacy_fetcher_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"legacy_fetchers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/legacy_fetchers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_legacy_fetchers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"legacy_fetchers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/questions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_question_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/questions/:question_id/files/:id(.:format)
 * @param {any} question_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_question_file_path = __jsr.r({"question_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/questions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_questions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"questions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/reports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"reports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/specializations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_specialization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"specializations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/specializations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_specializations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"specializations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/sub_topics/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_sub_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"sub_topics"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/sub_topics/:sub_topic_id/slides/:id(.:format)
 * @param {any} sub_topic_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_sub_topic_slide_path = __jsr.r({"sub_topic_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"sub_topics"],[2,[7,"/"],[2,[3,"sub_topic_id"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/sub_topics/:sub_topic_id/slides(.:format)
 * @param {any} sub_topic_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_sub_topic_slides_path = __jsr.r({"sub_topic_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"sub_topics"],[2,[7,"/"],[2,[3,"sub_topic_id"],[2,[7,"/"],[2,[6,"slides"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/sub_topics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_sub_topics_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"sub_topics"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/topics/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"topics"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/topics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_topics_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"topics"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backoffice_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/question_sets/clear(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clear_exam_type_question_sets_en_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"question_sets"],[2,[7,"/"],[2,[6,"clear"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/test/wyczysc(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clear_exam_type_question_sets_pl_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[6,"wyczysc"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/comments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const comment_en_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /comments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const comment_pl_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /en/comments/:comment_id/votes(.:format)
 * @param {any} comment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const comment_votes_en_path = __jsr.r({"comment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"comment_id"],[2,[7,"/"],[2,[6,"votes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /comments/:comment_id/votes(.:format)
 * @param {any} comment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const comment_votes_pl_path = __jsr.r({"comment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"comment_id"],[2,[7,"/"],[2,[6,"votes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/sub_topics/:id/delete_ppt(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_ppt_backoffice_sub_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"sub_topics"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_ppt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/sub_topics/:id/delete_slides(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_slides_backoffice_sub_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"sub_topics"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_slides"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/learnings/:id/duplicate(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const duplicate_exam_type_learning_en_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"learnings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"duplicate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/ucze-sie/:id/duplicate(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const duplicate_exam_type_learning_pl_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"ucze-sie"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"duplicate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/question_sets/:id/duplicate(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const duplicate_exam_type_question_set_en_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"question_sets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"duplicate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/test/:id/duplicate(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const duplicate_exam_type_question_set_pl_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"duplicate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/detailed_sets/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_backoffice_detailed_set_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"detailed_sets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/detailed_sets/:detailed_set_id/detailed_set_configs/:id/edit(.:format)
 * @param {any} detailed_set_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_backoffice_detailed_set_detailed_set_config_path = __jsr.r({"detailed_set_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"detailed_sets"],[2,[7,"/"],[2,[3,"detailed_set_id"],[2,[7,"/"],[2,[6,"detailed_set_configs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/exam_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_backoffice_exam_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/exam_types/:exam_type_id/exams/:id/edit(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_backoffice_exam_type_exam_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"exams"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/exam_types/:exam_type_id/question_sources/:id/edit(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_backoffice_exam_type_question_source_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"question_sources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/legacy_fetchers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_backoffice_legacy_fetcher_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"legacy_fetchers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/questions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_backoffice_question_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/reports/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_backoffice_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/specializations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_backoffice_specialization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"specializations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/sub_topics/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_backoffice_sub_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"sub_topics"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/sub_topics/:sub_topic_id/slides/:id/edit(.:format)
 * @param {any} sub_topic_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_backoffice_sub_topic_slide_path = __jsr.r({"sub_topic_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"sub_topics"],[2,[7,"/"],[2,[3,"sub_topic_id"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/topics/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_backoffice_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"topics"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/comments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_comment_en_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /comments/:id/edytuj(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_comment_pl_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edytuj"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/questions/:id/edit(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_exam_type_question_en_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/pytania/:id/edytuj(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_exam_type_question_pl_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"pytania"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edytuj"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/questions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_question_en_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pytania/:id/edytuj(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_question_pl_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pytania"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edytuj"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/question_sets/:id/end_exam(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const end_exam_exam_type_question_set_en_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"question_sets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"end_exam"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/question_sets/:question_set_id/exam_questions/:id/end_exam(.:format)
 * @param {any} exam_type_id
 * @param {any} question_set_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const end_exam_exam_type_question_set_exam_question_en_path = __jsr.r({"exam_type_id":{"r":true},"question_set_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"question_sets"],[2,[7,"/"],[2,[3,"question_set_id"],[2,[7,"/"],[2,[6,"exam_questions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"end_exam"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/test/:question_set_id/symulator/:id/end_exam(.:format)
 * @param {any} exam_type_id
 * @param {any} question_set_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const end_exam_exam_type_question_set_exam_question_pl_path = __jsr.r({"exam_type_id":{"r":true},"question_set_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[3,"question_set_id"],[2,[7,"/"],[2,[6,"symulator"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"end_exam"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/test/:id/end_exam(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const end_exam_exam_type_question_set_pl_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"end_exam"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_questions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_question_en_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_questions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /exam_question/index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_question_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"exam_question"],[2,[7,"/"],[2,[6,"index"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /symulator/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_question_pl_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"symulator"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /exam_questions/index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_questions_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"exam_questions"],[2,[7,"/"],[2,[6,"index"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/activities(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_activities_en_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"activities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/asystent-nauki(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_activities_pl_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"asystent-nauki"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/detailed_sets/:id(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_detailed_set_en_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"detailed_sets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/test-kontrolny/:id(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_detailed_set_pl_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"test-kontrolny"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/detailed_sets(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_detailed_sets_en_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"detailed_sets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/test-kontrolny(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_detailed_sets_pl_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"test-kontrolny"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_en_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/favorites(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_favorites_en_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"favorites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/schowek(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_favorites_pl_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"schowek"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/issues(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_issues_en_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"issues"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/zagadnienia(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_issues_pl_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"zagadnienia"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/learnings/:id(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_learning_en_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"learnings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/ucze-sie/:id(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_learning_pl_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"ucze-sie"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/learnings(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_learnings_en_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"learnings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/ucze-sie(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_learnings_pl_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"ucze-sie"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_pl_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/questions/:id(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_question_en_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/pytania/:id(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_question_pl_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"pytania"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/questions/:question_id/question_user_answers(.:format)
 * @param {any} exam_type_id
 * @param {any} question_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_question_question_user_answers_en_path = __jsr.r({"exam_type_id":{"r":true},"question_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"question_user_answers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/pytania/:question_id/question_user_answers(.:format)
 * @param {any} exam_type_id
 * @param {any} question_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_question_question_user_answers_pl_path = __jsr.r({"exam_type_id":{"r":true},"question_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"pytania"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"question_user_answers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/question_sets/:id(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_question_set_en_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"question_sets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/question_sets/:question_set_id/exam_questions(.:format)
 * @param {any} exam_type_id
 * @param {any} question_set_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_question_set_exam_questions_en_path = __jsr.r({"exam_type_id":{"r":true},"question_set_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"question_sets"],[2,[7,"/"],[2,[3,"question_set_id"],[2,[7,"/"],[2,[6,"exam_questions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/test/:question_set_id/symulator(.:format)
 * @param {any} exam_type_id
 * @param {any} question_set_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_question_set_exam_questions_pl_path = __jsr.r({"exam_type_id":{"r":true},"question_set_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[3,"question_set_id"],[2,[7,"/"],[2,[6,"symulator"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/test/:id(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_question_set_pl_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/question_sets(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_question_sets_en_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"question_sets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/test(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_question_sets_pl_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"test"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/questions(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_questions_en_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"questions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/pytania(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_questions_pl_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"pytania"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/slides/slides/:id(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_slides_slide_en_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/slides/slides/:id(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_slides_slide_pl_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/slides/topics/:id(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_slides_topic_en_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[6,"topics"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/slides/dziedziny/:id(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_slides_topic_pl_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[6,"dziedziny"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/slides/topics(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_slides_topics_en_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[6,"topics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/slides/dziedziny(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exam_type_slides_topics_pl_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[6,"dziedziny"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/sub_topics/:sub_topic_id/slides/:id/extract_text(.:format)
 * @param {any} sub_topic_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const extract_text_backoffice_sub_topic_slide_path = __jsr.r({"sub_topic_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"sub_topics"],[2,[7,"/"],[2,[3,"sub_topic_id"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"extract_text"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/favorites/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const favorite_en_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"favorites"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /schowek/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const favorite_pl_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"schowek"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/question_sets(/f/:filter)(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const filter_exam_type_question_sets_en_path = __jsr.r({"exam_type_id":{"r":true},"filter":{},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"question_sets"],[2,[1,[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[3,"filter"]]]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/test(/f/:filter)(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const filter_exam_type_question_sets_pl_path = __jsr.r({"exam_type_id":{"r":true},"filter":{},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"test"],[2,[1,[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[3,"filter"]]]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/questions(/topic/:topic)(/exam/:exam)(/specialization/:specialization)(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const filter_exam_type_questions_en_path = __jsr.r({"exam_type_id":{"r":true},"topic":{},"exam":{},"specialization":{},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"questions"],[2,[1,[2,[7,"/"],[2,[6,"topic"],[2,[7,"/"],[3,"topic"]]]]],[2,[1,[2,[7,"/"],[2,[6,"exam"],[2,[7,"/"],[3,"exam"]]]]],[2,[1,[2,[7,"/"],[2,[6,"specialization"],[2,[7,"/"],[3,"specialization"]]]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/pytania(/dziedzina/:topic)(/sesja/:exam)(/specjalziacja/:specialization)(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const filter_exam_type_questions_pl_path = __jsr.r({"exam_type_id":{"r":true},"topic":{},"exam":{},"specialization":{},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"pytania"],[2,[1,[2,[7,"/"],[2,[6,"dziedzina"],[2,[7,"/"],[3,"topic"]]]]],[2,[1,[2,[7,"/"],[2,[6,"sesja"],[2,[7,"/"],[3,"exam"]]]]],[2,[1,[2,[7,"/"],[2,[6,"specjalziacja"],[2,[7,"/"],[3,"specialization"]]]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/detailed_sets/:id/join(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const join_exam_type_detailed_set_en_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"detailed_sets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"join"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/test-kontrolny/:id/join(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const join_exam_type_detailed_set_pl_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"test-kontrolny"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"join"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /load-tests/64aa6bac-afb4-4b91-bc3f-071ccd19096a(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const load_tests_64aa6bac_afb4_4b91_bc3f_071ccd19096a_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"load-tests"],[2,[7,"/"],[2,[6,"64aa6bac-afb4-4b91-bc3f-071ccd19096a"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/sub_topics/:sub_topic_id/slides/:id/move(.:format)
 * @param {any} sub_topic_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const move_backoffice_sub_topic_slide_path = __jsr.r({"sub_topic_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"sub_topics"],[2,[7,"/"],[2,[3,"sub_topic_id"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"move"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/detailed_sets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_backoffice_detailed_set_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"detailed_sets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/detailed_sets/:detailed_set_id/detailed_set_configs/new(.:format)
 * @param {any} detailed_set_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_backoffice_detailed_set_detailed_set_config_path = __jsr.r({"detailed_set_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"detailed_sets"],[2,[7,"/"],[2,[3,"detailed_set_id"],[2,[7,"/"],[2,[6,"detailed_set_configs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/detailed_sets/:detailed_set_id/detailed_set_users/new(.:format)
 * @param {any} detailed_set_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_backoffice_detailed_set_detailed_set_user_path = __jsr.r({"detailed_set_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"detailed_sets"],[2,[7,"/"],[2,[3,"detailed_set_id"],[2,[7,"/"],[2,[6,"detailed_set_users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/exam_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_backoffice_exam_type_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/exam_types/:exam_type_id/exams/new(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_backoffice_exam_type_exam_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"exams"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/exam_types/:exam_type_id/question_sources/new(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_backoffice_exam_type_question_source_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"question_sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/legacy_fetchers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_backoffice_legacy_fetcher_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"legacy_fetchers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/questions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_backoffice_question_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/specializations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_backoffice_specialization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"specializations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/sub_topics/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_backoffice_sub_topic_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"sub_topics"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/sub_topics/:sub_topic_id/slides/new(.:format)
 * @param {any} sub_topic_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_backoffice_sub_topic_slide_path = __jsr.r({"sub_topic_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"sub_topics"],[2,[7,"/"],[2,[3,"sub_topic_id"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/topics/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_backoffice_topic_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"topics"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/questions/new(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_exam_type_question_en_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/pytania/nowe(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_exam_type_question_pl_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"pytania"],[2,[7,"/"],[2,[6,"nowe"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/questions/:question_id/question_user_answers/new(.:format)
 * @param {any} exam_type_id
 * @param {any} question_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_exam_type_question_question_user_answer_en_path = __jsr.r({"exam_type_id":{"r":true},"question_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"question_user_answers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/pytania/:question_id/question_user_answers/nowe(.:format)
 * @param {any} exam_type_id
 * @param {any} question_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_exam_type_question_question_user_answer_pl_path = __jsr.r({"exam_type_id":{"r":true},"question_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"pytania"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"question_user_answers"],[2,[7,"/"],[2,[6,"nowe"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/question_sets/new/:form(.:format)
 * @param {any} exam_type_id
 * @param {any} form
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_exam_type_question_set_en_path = __jsr.r({"exam_type_id":{"r":true},"form":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"question_sets"],[2,[7,"/"],[2,[6,"new"],[2,[7,"/"],[2,[3,"form"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/test/nowe/:form(.:format)
 * @param {any} exam_type_id
 * @param {any} form
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_exam_type_question_set_pl_path = __jsr.r({"exam_type_id":{"r":true},"form":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[6,"nowe"],[2,[7,"/"],[2,[3,"form"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/quick_question_sets/new(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_exam_type_quick_question_set_en_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"quick_question_sets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/quick_question_sets/nowe(.:format)
 * @param {any} exam_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_exam_type_quick_question_set_pl_path = __jsr.r({"exam_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"quick_question_sets"],[2,[7,"/"],[2,[6,"nowe"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/questions/:question_id/comments/new(.:format)
 * @param {any} question_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_question_comment_en_path = __jsr.r({"question_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pytania/:question_id/comments/nowe(.:format)
 * @param {any} question_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_question_comment_pl_path = __jsr.r({"question_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pytania"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[6,"nowe"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/questions/:question_id/reports/new(.:format)
 * @param {any} question_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_question_report_en_path = __jsr.r({"question_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pytania/:question_id/zgloszenia/nowe(.:format)
 * @param {any} question_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_question_report_pl_path = __jsr.r({"question_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pytania"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"zgloszenia"],[2,[7,"/"],[2,[6,"nowe"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/questions/:question_id/comments(.:format)
 * @param {any} question_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const question_comments_en_path = __jsr.r({"question_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pytania/:question_id/comments(.:format)
 * @param {any} question_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const question_comments_pl_path = __jsr.r({"question_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pytania"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /en/questions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const question_en_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /en/questions/:question_id/favorites(.:format)
 * @param {any} question_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const question_favorites_en_path = __jsr.r({"question_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"favorites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pytania/:question_id/schowek(.:format)
 * @param {any} question_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const question_favorites_pl_path = __jsr.r({"question_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pytania"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"schowek"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pytania/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const question_pl_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pytania"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /en/questions/:question_id/reports(.:format)
 * @param {any} question_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const question_reports_en_path = __jsr.r({"question_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"reports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pytania/:question_id/zgloszenia(.:format)
 * @param {any} question_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const question_reports_pl_path = __jsr.r({"question_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pytania"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"zgloszenia"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/properties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_properties_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"properties"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_routes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/mailers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"mailers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/question_sets/new/:form/rerender_full_session(.:format)
 * @param {any} exam_type_id
 * @param {any} form
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rerender_full_session_new_exam_type_question_set_en_path = __jsr.r({"exam_type_id":{"r":true},"form":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"question_sets"],[2,[7,"/"],[2,[6,"new"],[2,[7,"/"],[2,[3,"form"],[2,[7,"/"],[2,[6,"rerender_full_session"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/test/nowe/:form/rerender_full_session(.:format)
 * @param {any} exam_type_id
 * @param {any} form
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rerender_full_session_new_exam_type_question_set_pl_path = __jsr.r({"exam_type_id":{"r":true},"form":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[6,"nowe"],[2,[7,"/"],[2,[3,"form"],[2,[7,"/"],[2,[6,"rerender_full_session"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_path = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/question_sets/new/:form/sub_topic(.:format)
 * @param {any} exam_type_id
 * @param {any} form
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sub_topic_new_exam_type_question_set_en_path = __jsr.r({"exam_type_id":{"r":true},"form":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"question_sets"],[2,[7,"/"],[2,[6,"new"],[2,[7,"/"],[2,[3,"form"],[2,[7,"/"],[2,[6,"sub_topic"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/test/nowe/:form/sub_topic(.:format)
 * @param {any} exam_type_id
 * @param {any} form
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sub_topic_new_exam_type_question_set_pl_path = __jsr.r({"exam_type_id":{"r":true},"form":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[6,"nowe"],[2,[7,"/"],[2,[3,"form"],[2,[7,"/"],[2,[6,"sub_topic"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/topics/:topic_id/sub_topics(.:format)
 * @param {any} topic_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const topic_sub_topics_en_path = __jsr.r({"topic_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"topics"],[2,[7,"/"],[2,[3,"topic_id"],[2,[7,"/"],[2,[6,"sub_topics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dziedziny/:topic_id/dzialy(.:format)
 * @param {any} topic_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const topic_sub_topics_pl_path = __jsr.r({"topic_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dziedziny"],[2,[7,"/"],[2,[3,"topic_id"],[2,[7,"/"],[2,[6,"dzialy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /en/topics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const topics_en_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"topics"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /dziedziny(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const topics_pl_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dziedziny"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/exam_types/:exam_type_id/question_sets/:id/update_timer(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_timer_exam_type_question_set_en_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"exam_types"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"question_sets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_timer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /egzamin/:exam_type_id/test/:id/update_timer(.:format)
 * @param {any} exam_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_timer_exam_type_question_set_pl_path = __jsr.r({"exam_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"egzamin"],[2,[7,"/"],[2,[3,"exam_type_id"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_timer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/sub_topics/:id/upload_ppt(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_ppt_backoffice_sub_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"sub_topics"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload_ppt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/sub_topics/:id/upload_slide(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_slide_backoffice_sub_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"sub_topics"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload_slide"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /backoffice/sub_topics/:id/upload_zip(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_zip_backoffice_sub_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"backoffice"],[2,[7,"/"],[2,[6,"sub_topics"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload_zip"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /en/votes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const vote_en_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"en"],[2,[7,"/"],[2,[6,"votes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /votes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const vote_pl_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"votes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

