import { Controller } from "@hotwired/stimulus"
import axios from "axios";
import autocomplete from "autocomplete.js";
import { backoffice_detailed_set_detailed_set_user_path } from 'routes';

// Connects to data-controller="backoffice--detailed-set-users"
export default class extends Controller {
  static targets = ["query"];

  source() {
    const url = this.data.get("url");
    return (query, callback) => {
      axios.get(url, { params: { query } }).then((response) => {
        callback(response.data);
      });
    };
  }

  connect() {
    this.ac = autocomplete(this.queryTarget, { hint: false }, [
      {
        source: this.source(),
        debounce: 200,
        templates: {
          suggestion: function (suggestion) {
            return suggestion.id + ' ' + suggestion.email + ' ' + suggestion.first_name + ' ' + suggestion.last_name;
          },
        },
      },
    ]).on("autocomplete:selected", (event, suggestion, dataset, context) => {
      // const detailed_set_id = this.data.get("detailed_set_id");
      // const csrfToken = document.querySelector("meta[name=csrf-token]").content
      // axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
      // axios.post('/backoffice/detailed_sets/' + detailed_set_id + '/detailed_set_users', { detailed_set_user: { user_id: suggestion.id } }).then((response) => {
      //   callback(response.data);
      // });
      this.ac.autocomplete.setVal(suggestion.id);
    });
  }

  submitForm(){
    Rails.fire(this.formTarget, 'submit')
  }
}
